import {
  parentCategoryKidsId,
  parentCategoryLifestyleId,
  parentCategoryMenId,
  parentCategoryWomenId,
} from 'assets/constants';
import { IParentCategoryTab, TParentCategoryId, TParentCategoryUrl } from '~/types/main';

export const useCategories = () => {
  const { isInternational } = useInternational();

  const womenTranslate = 'composables.useGender.women';
  const menTranslate = 'composables.useGender.men';
  const kidsTranslate = 'composables.useGender.kids';
  const lifestyleTranslate = 'composables.useGender.lifestyle';

  const parentCategoryWomenName = computed(() => `${isInternational ? 'women' : 'zhenskoe'}`);
  const parentCategoryMenName = computed(() => `${isInternational ? 'men' : 'muzhskoe'}`);
  const parentCategoryKidsName = computed(() => `${isInternational ? 'kids' : 'detskoe'}`);
  const parentCategoryLifestyleName = computed(() => 'lifestyle');
  const parentCategoriesIdForNameMap = computed<Record<TParentCategoryId, TParentCategoryUrl>>(() => ({
    [parentCategoryWomenId]: parentCategoryWomenName.value,
    [parentCategoryMenId]: parentCategoryMenName.value,
    [parentCategoryKidsId]: parentCategoryKidsName.value,
    [parentCategoryLifestyleId]: parentCategoryLifestyleName.value,
  }));
  const parentCategoriesIdForTranslateNameMap = computed<Record<TParentCategoryId, TParentCategoryUrl>>(() => ({
    [parentCategoryWomenId]: womenTranslate,
    [parentCategoryMenId]: menTranslate,
    [parentCategoryKidsId]: kidsTranslate,
    [parentCategoryLifestyleId]: lifestyleTranslate,
  }));
  const parentCategoriesNameForIdMap = computed<Record<TParentCategoryUrl, TParentCategoryId>>(() => ({
    [parentCategoryWomenName.value]: parentCategoryWomenId,
    [parentCategoryMenName.value]: parentCategoryMenId,
    [parentCategoryKidsName.value]: parentCategoryKidsId,
    [parentCategoryLifestyleName.value]: parentCategoryLifestyleId,
  }));
  const parentCategoriesURLForNameMap = computed(() => ({
    women: parentCategoryWomenName.value,
    men: parentCategoryMenName.value,
    kids: parentCategoryKidsName.value,
    lifestyle: parentCategoryLifestyleName.value,
  }));
  const parentCategoriesSearchTabs = computed<IParentCategoryTab[]>(() => ([
    {
      id: 'women',
      name: womenTranslate,
      url: parentCategoryWomenName.value,
      categoryId: parentCategoryWomenId,
    },
    {
      id: 'men',
      name: menTranslate,
      url: parentCategoryMenName.value,
      categoryId: parentCategoryMenId,
    },
    {
      id: 'kids',
      name: kidsTranslate,
      url: parentCategoryKidsName.value,
      categoryId: parentCategoryKidsId,
    },
    {
      id: 'lifestyle',
      name: lifestyleTranslate,
      url: parentCategoryLifestyleName.value,
      categoryId: parentCategoryLifestyleId,
    },
  ]));
  const menCatalogUrl = computed(() => `/catalog/${parentCategoryMenName.value}`);
  const womenCatalogUrl = computed(() => `/catalog/${parentCategoryWomenName.value}`);
  const kidsCatalogUrl = computed(() => `/catalog/${parentCategoryKidsName.value}`);
  const lifestyleCatalogUrl = computed(() => `/catalog/${parentCategoryLifestyleName.value}`);

  return {
    parentCategoryWomenName,
    parentCategoryMenName,
    parentCategoryKidsName,
    parentCategoryLifestyleName,
    parentCategoriesIdForNameMap,
    parentCategoriesIdForTranslateNameMap,
    parentCategoriesNameForIdMap,
    parentCategoriesURLForNameMap,
    parentCategoriesSearchTabs,
    menCatalogUrl,
    womenCatalogUrl,
    kidsCatalogUrl,
    lifestyleCatalogUrl,
  };
};
